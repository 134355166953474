// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Context
import { TableContext } from 'contexts/TableContext';

// Utils
import { exportToExcel } from 'utils/functions';

// Assets
import type0 from 'img/program-type.png';
import type2 from 'img/program-type2.png';
import type3 from 'img/program-type3.png';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkIcon from '@material-ui/icons/Work';
import PersonPinIcon from '@material-ui/icons/PersonPin';

// Elements
import { Grid, List, ListItem } from '@material-ui/core';
import Table from 'components/organisms/Table/Table';
import FilterPanel from 'components/organisms/FilterPanel/FilterPanel';
import DashCard from 'components/molecules/DashCard/DashCard';
import TableOptions from 'components/molecules/TableOptions/TableOptions';

// Styles
import {
  StyledListItemText,
  StyledListItemIcon,
  StyledWrapper,
  StyledOptions,
} from './TableTemplate.styles';

// Component
const TableTemplate = ({
  headCells,
  data,
  tableType,
  isnav,
  rowActions,
  noSort,
  noPagination,
  noFilter,
  actionCells,
  navCells,
  link,
  select,
  filters,
  translators,
  tableTools,
  actions,
  tableBene,
  updateSelected,
  selectedRows,
  tableName,
  defaultSortColumn,
  defaultSortDirection,
}) => {
  const [gridView, setGridView] = useState(false);
  const [isFilterHide, setIsFilterHide] = useState(true);
  const [search, setSearch] = useState('');
  const [currentData, setCurrentData] = useState([]);
  const [random, setRandom] = useState(Math.random());
  const [currentFilter, setCurrentFilter] = useState([]);
  const [isCollapse] = useState(true);
  const [possibleOptions, setPossibleOptions] = useState([]);
  const [localActions, setLocalActions] = useState([]);

  useEffect(() => {
    if (currentData.length > 0 && headCells.length > 0) {
      setLocalActions([
        ...actions,
        {
          name: 'Exportuj do Excel',
          action: () => exportToExcel(currentData, headCells, tableName),
        },
      ]);
    } else {
      setLocalActions([...actions]);
    }
  }, [currentData, headCells]);

  useEffect(() => {
    if (filters) {
      filters.map((item) => {
        possibleOptions[item.key] = {
          options: [],
          label: item.label,
          type: item.type ? item.type : null,
          range_key: item.range_key ? item.range_key : null,
          translator:
            translators && translators[item.key]
              ? (value) => {
                  const found = translators[item.key].find((post) => {
                    if (post.value === value) return true;

                    return false;
                  });

                  if (found && found.name) {
                    return found.name;
                  }

                  return value;
                }
              : null,
        };

        currentFilter[item.key] = false;

        return true;
      });
    }
    if (data) {
      data.map((item) => {
        Object.keys(item).map((key) => {
          if (possibleOptions[key] && item[key]) {
            if (possibleOptions[key].type === 'array' && Array.isArray(item[key])) {
              item[key].map((obj) => {
                if (
                  possibleOptions[key].options.indexOf(typeof obj === 'object' ? obj.name : obj) ===
                  -1
                )
                  possibleOptions[key].options.push(typeof obj === 'object' ? obj.name : obj);
                return true;
              });
            } else if (possibleOptions[key].options.indexOf(item[key]) === -1) {
              possibleOptions[key].options.push(item[key]);
            }
            possibleOptions[key].options = possibleOptions[key].options.sort();
          }
          return true;
        });

        return true;
      });
    }

    setCurrentFilter(currentFilter);
    setPossibleOptions(possibleOptions.sort());
  }, [data, filters]);

  const checkFilters = (afterSearchData) => {
    return (
      afterSearchData &&
      afterSearchData.filter((row) => {
        let score = 0;

        Object.keys(currentFilter).map((key) => {
          if (currentFilter[key] === false) {
            score += 1;

            return true;
          }

          if (
            possibleOptions[key].type &&
            (possibleOptions[key].type === 'range_from' || possibleOptions[key].type === 'range_to')
          ) {
            if (possibleOptions[key].type === 'range_from') {
              if (currentFilter[key] <= row[possibleOptions[key].range_key]) {
                score += 1;
                return true;
              }
            }
            if (possibleOptions[key].type === 'range_to') {
              if (currentFilter[key] >= row[possibleOptions[key].range_key]) {
                score += 1;
                return true;
              }
            }
          } else if (Array.isArray(row[key]) && row[key] !== null) {
            const found = row[key].find((post) => {
              if (post.name === currentFilter[key]) return true;

              return false;
            });

            if (found) {
              score += 1;

              return true;
            }
          } else if (currentFilter[key] === row[key]) {
            score += 1;

            return true;
          }
          return false;
        });

        if (score === Object.keys(currentFilter).length) return row;

        return false;
      })
    );
  };

  useEffect(() => {
    const filteredData = () => {
      if (search.length === 0) return checkFilters(data);

      const lowerSearch = search.toLowerCase();

      return (
        data &&
        checkFilters(
          data.filter((row) => {
            let isFound = false;

            Object.keys(row).map((item) => {
              const lowerString = String(row[item]).toLowerCase();

              if (lowerString && lowerSearch && lowerString.includes(lowerSearch)) isFound = true;

              return true;
            });

            if (isFound) return row;

            return false;
          }),
        )
      );
    };
    const filteredDataReturned = filteredData();
    setCurrentData(filteredDataReturned && filteredDataReturned.map((item) => item));
  }, [search, random, data]);

  const setFilterValue = (objectValue) => {
    Object.keys(objectValue).map((key) => {
      currentFilter[key] = objectValue[key];

      return true;
    });

    setCurrentFilter(currentFilter);
    setRandom(Math.random());
  };

  const selectBadge = (badge) => {
    switch (badge) {
      case 'Konsorcjum':
        return type3;
      case 'Indywidualny':
        return type2;
      default:
        return type0;
    }
  };

  return (
    <TableContext.Provider value={{ search, setSearch }}>
      <StyledWrapper filterHide={!isFilterHide || false}>
        <StyledOptions container>
          {tableTools ? (
            <></>
          ) : (
            <TableOptions
              gridView={gridView}
              tableType={tableType}
              setGridView={setGridView}
              isFilterHide={isFilterHide}
              setIsFilterHide={setIsFilterHide}
              actions={localActions}
              tableBene={tableBene}
              noFilter={noFilter}
            />
          )}
        </StyledOptions>
        {gridView ? (
          <Grid container spacing={2}>
            {data.map(
              ({
                name,
                type,
                start_date,
                end_date,
                coordinator,
                coordinator_phone,
                id,
                organizations_number,
              }) => (
                <Grid key={id} item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <DashCard
                    actionCard={isnav || false}
                    key={id}
                    size={3}
                    title={name}
                    image={null}
                    collapse={isCollapse}
                    badge={selectBadge(type)}
                    link={link}
                    id={id}
                  >
                    <List>
                      <ListItem>
                        <StyledListItemIcon>
                          <DateRangeIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary={`od ${start_date} do ${end_date}`} />
                      </ListItem>
                      <ListItem>
                        <StyledListItemIcon>
                          <PersonPinIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary={coordinator} />
                      </ListItem>
                      <ListItem>
                        <StyledListItemIcon>
                          <PhoneIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary={coordinator_phone} />
                      </ListItem>
                      <ListItem>
                        <StyledListItemIcon>
                          <WorkIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary={organizations_number} />
                      </ListItem>
                    </List>
                  </DashCard>
                </Grid>
              ),
            )}{' '}
          </Grid>
        ) : (
          <Grid container>
            <Table
              headCells={headCells}
              navCells={navCells}
              data={currentData}
              rowActions={rowActions || false}
              rowLink={link}
              isnav={isnav || false}
              actionCells={actionCells}
              select={select || false}
              possibleOptions={possibleOptions || []}
              updateSelected={updateSelected}
              selectedRows={selectedRows}
              noSort={noSort}
              noPagination={noPagination}
              defaultSortColumn={defaultSortColumn}
              defaultSortDirection={defaultSortDirection}
            />
            {!noFilter && (
              <FilterPanel
                currentFilter={currentFilter}
                isFilterHide={isFilterHide}
                setIsFilterHide={setIsFilterHide}
                possibleOptions={possibleOptions}
                setFilterValue={setFilterValue}
              />
            )}
          </Grid>
        )}
      </StyledWrapper>
    </TableContext.Provider>
  );
};

TableTemplate.propTypes = {
  updateSelected: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.any),
  navCells: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any),
  tableType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isnav: PropTypes.bool,
  rowActions: PropTypes.arrayOf(PropTypes.any),
  actionCells: PropTypes.arrayOf(PropTypes.any),
  link: PropTypes.string,
  select: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.any),
  translators: PropTypes.objectOf(PropTypes.any),
  tableTools: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.any),
  tableBene: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.any),
  tableName: PropTypes.string,
  noSort: PropTypes.bool,
  noPagination: PropTypes.bool,
  noFilter: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
};

TableTemplate.defaultProps = {
  updateSelected: null,
  selectedRows: [],
  tableBene: false,
  actions: [],
  headCells: [''],
  navCells: false,
  data: [''],
  tableType: '',
  isnav: false,
  rowActions: [],
  actionCells: [],
  link: '',
  select: false,
  filters: [],
  translators: null,
  tableTools: false,
  tableName: 'export',
  noSort: false,
  noPagination: false,
  noFilter: false,
  defaultSortColumn: '',
  defaultSortDirection: 'asc',
};

export default TableTemplate;
