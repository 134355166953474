// React
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Elements
import {
  Grid,
  Table as AppTable,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  IconButton,
} from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import MoreIcon from 'components/molecules/MoreIcon/MoreIcon';
import Checkbox from 'components/atoms/Checkbox/Checkbox';

// import { Virtuoso } from 'react-virtuoso';

// Styles
import {
  StyledTablePagination,
  StyledTableRow,
  StyledTableCell,
  StyledTableContainer,
  CheckboxCell,
  StyledHeading,
  StyledButton,
  StyledTableSortLabel,
} from './Table.styles';

// Functions
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }
  return false;
}

// Component
const Table = ({
  headCells,
  data,
  isnav,
  noSort,
  noPagination,
  rowLink,
  rowActions,
  navCells,
  actionCells,
  select,
  possibleOptions,
  updateSelected,
  selectedRows,
  defaultSortColumn,
  defaultSortDirection,
}) => {
  const [order, setOrder] = useState(defaultSortDirection || 'asc');
  const [orderBy, setOrderBy] = useState(defaultSortColumn || 'id');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(selectedRows || []);
  const history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigateTo = (id) => {
    if (String(id).startsWith('anonim_')) {
      return false;
    }

    const { pathname } = history.location;
    if (isnav) {
      if (rowLink) {
        history.push(`${rowLink}/${id}`);
      } else {
        history.push(`${pathname}/${id}`);
      }
    }
    return true;
  };

  const navigateToCell = (id) => {
    if (String(id).startsWith('anonim_')) {
      return false;
    }
    const { pathname } = history.location;
    if (rowLink) {
      history.push(`${rowLink}/${id}`);
    } else {
      history.push(`${pathname}/${id}`);
    }
    return true;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data; //
      setSelected(newSelecteds);
      if (updateSelected) {
        updateSelected(newSelecteds);
      }
      return;
    }
    setSelected([]);
    if (updateSelected) {
      updateSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (updateSelected) {
      updateSelected(newSelected);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setSelected(selectedRows);
  }, [selectedRows]);

  return (
    <Grid className="table-cont" item xs={12} /* style={{ height: '100vh' }} */>
      <StyledTableContainer component={Paper} /* style={{ height: '100%' }} */>
        {/* <Virtuoso
          data={data}
          itemContent={(index, row) => {
            const isItemSelected = isSelected(row);
            const cells = headCells.map(({ type }) => type);
            return (
              <StyledTableRow
                hover
                key={row.id}
                onClick={(e) => {
                  navigateTo(row.id);
                  if (select) {
                    handleClick(e, row);
                  }
                }}
                nav={isnav ? 1 : 0}
              >
                {select && (
                  <CheckboxCell padding="checkbox" align="center">
                    <Checkbox isEditable value={isItemSelected} />
                  </CheckboxCell>
                )}
                <StyledTableCell align="center" key="lp2">
                  <Heading.Body2>{index + 1}.</Heading.Body2>
                </StyledTableCell>
                {Object.keys(row).map((item) => {
                  if (cells && cells.includes(item)) {
                    return (
                      <StyledTableCell
                        align="center"
                        key={item}
                        pointercursor={navCells ? 'true' : undefined}
                      >
                        {typeof row[item] === 'boolean' ? (
                          <Checkbox value={row[item] || false} />
                        ) : (
                          <Heading.Body2>{row[item] || '-'}</Heading.Body2>
                        )}
                      </StyledTableCell>
                    );
                  }
                  return false;
                })}

                {actionCells.length && (!row.id || !String(row.id).startsWith('anonim_'))
                  ? actionCells.map((cell) => (
                      <StyledTableCell key={cell.column} align="center">
                        {cell.icon ? (
                          <IconButton
                            disabled={row.disableActions}
                            onClick={() => cell.action && cell.action(row, cell.param)}
                          >
                            {cell.icon}
                          </IconButton>
                        ) : (
                          <StyledButton onClick={() => cell.action && cell.action(row, cell.param)}>
                            {cell.button}
                          </StyledButton>
                        )}
                      </StyledTableCell>
                    ))
                  : null}

                {rowActions.length && (!row.id || !String(row.id).startsWith('anonim_')) ? (
                  <StyledTableCell align="right">
                    <MoreIcon actions={rowActions} row={row} index={index} />
                  </StyledTableCell>
                ) : null}
              </StyledTableRow>
            );
          }}
        /> */}

        <AppTable stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {select && (
                <CheckboxCell padding="checkbox" align="center">
                  <Checkbox
                    isEditable
                    value={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                </CheckboxCell>
              )}
              <StyledTableCell header="true" key="lp2" align="left">
                <Heading.Body2>l.p.</Heading.Body2>
              </StyledTableCell>
              {headCells.length &&
                headCells.map(({ label, type, cellNoSort, align }) => (
                  <StyledTableCell
                    header="true"
                    key={label}
                    sortDirection={orderBy === type ? order : false}
                    align={align || 'left'}
                  >
                    {noSort || cellNoSort ? (
                      <Heading.Body2>{label}</Heading.Body2>
                    ) : (
                      <StyledTableSortLabel
                        onClick={createSortHandler(type)}
                        active={orderBy === type}
                        direction={orderBy === type ? order : 'asc'}
                      >
                        <Heading.Body2>{label}</Heading.Body2>
                      </StyledTableSortLabel>
                    )}
                  </StyledTableCell>
                ))}
              {actionCells.length
                ? actionCells.map((cell) => (
                    <StyledTableCell header="true" key={cell.column} align="center">
                      <StyledHeading>{cell.column}</StyledHeading>
                    </StyledTableCell>
                  ))
                : null}
              {rowActions.length ? <StyledTableCell header="true" /> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {(noPagination
              ? stableSort(data, getComparator(order, orderBy))
              : stableSort(data, getComparator(order, orderBy)).slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
            ).map((row, index) => {
              const isItemSelected = isSelected(row);
              const cells = headCells.map(({ type }) => type);
              return (
                <StyledTableRow
                  hover
                  key={row.id}
                  onClick={(e) => {
                    navigateTo(row.id);
                    if (select) {
                      handleClick(e, row);
                    }
                  }}
                  nav={isnav ? 1 : 0}
                >
                  {select && (
                    <CheckboxCell padding="checkbox" align="center">
                      <Checkbox isEditable value={isItemSelected} />
                    </CheckboxCell>
                  )}
                  <StyledTableCell align="center" key="lp2">
                    <Heading.Body2>{page * rowsPerPage + index + 1}.</Heading.Body2>
                  </StyledTableCell>
                  {Object.keys(row).map((item) => {
                    if (cells && cells.includes(item)) {
                      return (
                        <StyledTableCell
                          align="center"
                          key={item}
                          pointercursor={navCells ? 'true' : undefined}
                          onClick={() => {
                            if (
                              navCells ||
                              headCells.find((element) => element.type === item).nav
                            ) {
                              navigateToCell(row.id);
                            }
                          }}
                        >
                          {typeof row[item] === 'boolean' ? (
                            <Checkbox value={row[item] || false} />
                          ) : (
                            <Heading.Body2>
                              {row[item] && typeof row[item] === 'object' ? (
                                <>
                                  {row[item] !== null &&
                                    row[item].length > 0 &&
                                    row[item].map((obj) => {
                                      return (
                                        <span key={obj.id}>
                                          {obj.name}
                                          <br />
                                        </span>
                                      );
                                    })}
                                </>
                              ) : (
                                <>
                                  {possibleOptions[item] && possibleOptions[item].translator
                                    ? possibleOptions[item].translator(row[item])
                                    : row[item] || '-'}
                                </>
                              )}
                            </Heading.Body2>
                          )}
                        </StyledTableCell>
                      );
                    }
                    return false;
                  })}

                  {actionCells.length && (!row.id || !String(row.id).startsWith('anonim_'))
                    ? actionCells.map((cell) => (
                        <StyledTableCell key={cell.column} align="center">
                          {cell.icon ? (
                            <IconButton
                              disabled={row.disableActions}
                              style={
                                row.disableActions && cell.hideIfDisabled ? { display: 'none' } : {}
                              }
                              edge="start"
                              aria-label="menu"
                              onClick={() =>
                                cell.action
                                  ? cell.action(row, cell.param ? cell.param : null)
                                  : null
                              }
                            >
                              {cell.icon}
                            </IconButton>
                          ) : (
                            <StyledButton
                              onClick={() =>
                                cell.action
                                  ? cell.action(row, cell.param ? cell.param : null)
                                  : null
                              }
                              button_type={
                                cell.buttonType && cell.buttonType(row) ? cell.buttonType(row) : ''
                              }
                            >
                              {cell.buttonIcon && cell.buttonIcon(row)}
                              {cell.button}
                            </StyledButton>
                          )}
                        </StyledTableCell>
                      ))
                    : null}

                  {rowActions.length && (!row.id || !String(row.id).startsWith('anonim_')) ? (
                    <StyledTableCell align="right">
                      <MoreIcon actions={rowActions} row={row} index={index} />
                    </StyledTableCell>
                  ) : null}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </AppTable>
        {noPagination ? null : (
          <StyledTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Wiersze na stronie"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} na ${count !== -1 ? count : `więcej niż ${to}`}`
            }
          />
        )}
      </StyledTableContainer>
    </Grid>
  );
};

Table.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.any),
  updateSelected: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  isnav: PropTypes.bool,
  noSort: PropTypes.bool,
  noPagination: PropTypes.bool,
  rowActions: PropTypes.arrayOf(PropTypes.any),
  actionCells: PropTypes.arrayOf(PropTypes.any),
  select: PropTypes.bool,
  navCells: PropTypes.bool,
  rowLink: PropTypes.string,
  possibleOptions: PropTypes.arrayOf(PropTypes.any),
  defaultSortColumn: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
};

Table.defaultProps = {
  selectedRows: [],
  updateSelected: null,
  headCells: [''],
  data: [],
  navCells: false,
  isnav: false,
  noSort: false,
  noPagination: false,
  rowActions: [],
  actionCells: [],
  select: false,
  rowLink: null,
  possibleOptions: [],
  defaultSortColumn: '',
  defaultSortDirection: 'asc',
};

export default Table;
