// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchOrganizationList as fetchOrganizationListService,
  fetchCoordinatorsList as fetchCoordinatorsListService,
} from 'services/organizationServices';
import {
  fetchProgram as fetchProgramService,
  editProgram as editProgramService,
  fetchProgramCoordinatorsList as fetchProgramCoordinatorsListService,
} from 'services/programServices';

// Actions
import {
  clearOrganizationAction,
  clearCoordinatorsList as clearCoordinatorsListAction,
} from 'actions/organizationActions';
import {
  clearProgramAction,
  clearProgramCoordinatorsList as clearProgramCoordinatorsListAction,
} from 'actions/programActions';

// Formik
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';

// Data
import { COORDINATOR, PROGRAM_COORDINATOR } from 'constants/roles';
import { programType /* , programColor */ } from 'constants/selectLists';

// Element
import { Grid } from '@material-ui/core';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Select/Select';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Calendar from 'components/atoms/Calendar/Calendar';
import EditButton from 'components/atoms/EditButton/EditButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import { StyledGrid, StyledButton, StyledTitle } from './ProgramSettings.styles';

// Component
const ProgramSettings = ({
  clearProgram,
  fetchProgram,
  details,
  clearOrganization,
  fetchOrganizationList,
  organizations,
  clearProgramCoordinatorsList,
  fetchProgramCoordinatorsList,
  clearCoordinatorsList,
  fetchCoordinatorsList,
  coordinators,
  editProgram,
  isNew,
  me,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    clearProgram();
    clearOrganization();
    clearProgramCoordinatorsList();
    clearCoordinatorsList();
  }, []);

  const triggerFetchProgram = useCallback(
    (myid) => {
      if (myid !== undefined && !isNew) {
        return fetchProgram(myid).then((result) => {
          if (result === 404) {
            history.push(`/programy`);
          }
        });
      }

      return setIsEditable(true);
    },
    [fetchProgram],
  );

  useEffect(() => {
    if (isNew) {
      setIsEditable(true);

      return false;
    }

    setIsEditable(false);

    fetchProgramCoordinatorsList(id);

    return triggerFetchProgram(id);
  }, [id]);

  const triggerFetchList = useCallback(() => fetchOrganizationList(), [fetchOrganizationList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  return (
    <Formik
      initialValues={{
        name: details.name && !isNew ? details.name : '',
        description: details.description && !isNew ? details.description : '',
        dateStart: details.date_start && !isNew ? details.date_start : null,
        dateEnd: details.date_end && !isNew ? details.date_end : null,
        contractNumber: details.contract_number && !isNew ? details.contract_number : '',
        isPWD: details.is_pwd && !isNew ? details.is_pwd : false,
        type: details.type && !isNew ? details.type : '',
        color: details.color && !isNew ? details.color : '',
        organization: details.organization && !isNew ? details.organization : '',
        coordinator: details.coordinator && !isNew ? details.coordinator : '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        name: Yup.string()
          .min(2, 'Minimum 2 znaki')
          .max(100, 'Maksymalnie 100 znaków')
          .required('pole wymagane!'),
        type: isNew
          ? Yup.number()
              .required('pole wymagane!')
              .test('Is positive?', 'pole wymagane!', (value) => value > 0)
          : null,
        organization: !id
          ? Yup.number()
              .required('pole wymagane!')
              .test('Is positive?', 'pole wymagane!', (value) => value > 0)
          : null,
        // color: Yup.string()
        //   .min(7, 'Minimum 7 znaki')
        //   .max(7, 'Maksymalnie 7 znaków')
        //   .required('pole wymagane!'),
        description: Yup.string()
          .min(2, 'Minimum 2 znaki')
          .max(1000, 'Maksymalnie 1000 znaków')
          .required('pole wymagane!'),
        dateStart: Yup.string().nullable().required('pole wymagane!'),
        dateEnd: Yup.string()
          .nullable()
          .required('pole wymagane!')
          .test(
            'is-greater',
            'Data zakończenia musi być późniejsza niż data rozpoczęcia',
            (value, context) => {
              const { dateStart } = context.parent;
              return !dateStart || !value || new Date(value) > new Date(dateStart);
            },
          ),
        contractNumber: Yup.string()
          .min(2, 'Minimum 2 znaki')
          .max(64, 'Maksymalnie 64 znaków')
          .required('pole wymagane!'),
      })}
      onSubmit={(values) => {
        editProgram(id === undefined || isNew ? 'new' : id, values).then((data) => {
          if (data && (id === undefined || isNew) && data.data.id) {
            triggerFetchProgram(data.data.id);
            history.push(`/programy/${data.data.id}`);
          }
        });
      }}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <>
          <StyledTitle>
            {!isNew && id !== undefined && (
              <GuardedComponent
                allowed_user_roles={[COORDINATOR]}
                program_id={parseInt(id, 10)}
                allowed_program_roles={[PROGRAM_COORDINATOR]}
              >
                <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
              </GuardedComponent>
            )}
          </StyledTitle>
          <StyledGrid container spacing={2}>
            {isNew ? (
              <Grid item xs={8}>
                <Select
                  disabled={!isEditable || false}
                  name="type"
                  label="Typ programu"
                  text="type"
                  selectValue={values.type}
                  data={programType}
                  variant="filled"
                  onChange={setFieldValue}
                />
                <ErrorMessage name="type">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Heading.Subtitle1>
                  Typ programu: {values.type === 1 ? 'Konsorcjalny' : 'Indywidualny'}
                </Heading.Subtitle1>
                <Heading.Subtitle1>
                  {values.type === 2 && <>Organizacja: {details.organization}</>}
                </Heading.Subtitle1>
              </Grid>
            )}
            <Grid item xs={8}>
              <Input
                label="Nazwa programu"
                variant="filled"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                disabled={!isEditable || (id && !me.user_role.includes(COORDINATOR))}
                className="pogrubiona_nazwa"
              />
              <ErrorMessage name="name">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            {!id && (
              <Grid item xs={8}>
                <Select
                  disabled={!isEditable || false}
                  name="organization"
                  label="Organizacja"
                  text="organization"
                  selectValue={values.organization}
                  data={organizations.map((el) => {
                    return {
                      value: el.id,
                      name: el.name,
                    };
                  })}
                  variant="filled"
                  onChange={(e, value) => {
                    setFieldValue(e, value);

                    if (value) {
                      fetchCoordinatorsList(value);
                    } else {
                      setFieldValue('coordinator', null);
                    }

                    return true;
                  }}
                />
                <ErrorMessage name="organization">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
            )}
            <Grid item xs={6}>
              {coordinators.length > 0 && (
                <Select
                  disabled={!isEditable || false}
                  name="coordinator"
                  label="Koordynator"
                  text="coordinator"
                  selectValue={values.coordinator}
                  data={coordinators.map((item) => {
                    return { value: item.id, name: `${item.name} ${item.surname}` };
                  })}
                  variant="filled"
                  onChange={setFieldValue}
                />
              )}
              <ErrorMessage name="coordinator">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            {/* <Grid item xs={6}>
              <Select
                disabled={!isEditable || false}
                name="color"
                label="Kolor"
                text="color"
                selectValue={values.color}
                data={programColor}
                variant="filled"
                onChange={setFieldValue}
              />
              <ErrorMessage name="color">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid> */}
            <Grid item xs={12}>
              <Input
                label="Opis programu"
                disabled={!isEditable || false}
                type="text"
                multiline
                rows={10}
                charCounter={1000}
                setField={setFieldValue}
                value={values.description}
                variant="filled"
                name="description"
                onChange={handleChange}
              />
              <ErrorMessage name="description">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={6}>
              <Calendar
                calendarToolbar={false}
                label="Data rozpoczęcia"
                variant="filled"
                name="dateStart"
                onChange={setFieldValue}
                value={values.dateStart}
                disabled={!isEditable || (id && !me.user_role.includes(COORDINATOR))}
                placeholder="RRRR-MM-DD"
              />
              <ErrorMessage name="dateStart">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={6}>
              <Calendar
                calendarToolbar={false}
                label="Data zakończenia"
                variant="filled"
                name="dateEnd"
                onChange={setFieldValue}
                value={values.dateEnd}
                disabled={!isEditable || (id && !me.user_role.includes(COORDINATOR))}
                placeholder="RRRR-MM-DD"
              />
              <ErrorMessage name="dateEnd">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Nr umowy"
                variant="filled"
                name="contractNumber"
                type="text"
                onChange={handleChange}
                value={values.contractNumber || ''}
                disabled={!isEditable || (id && !me.user_role.includes(COORDINATOR))}
              />
              <ErrorMessage name="contractNumber">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Checkbox
                label="Placówka wsparcia dziennego"
                name="isPWD"
                onChange={handleChange}
                value={values.isPWD || false}
                isEditable={(isEditable && me.user_role.includes(COORDINATOR)) || !id}
              />
              <ErrorMessage name="isPWD">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <GuardedComponent
                allowed_user_roles={[COORDINATOR]}
                program_id={parseInt(id, 10)}
                allowed_program_roles={[PROGRAM_COORDINATOR]}
              >
                <StyledButton
                  variant="outlined"
                  disabled={!isEditable || false}
                  onClick={handleSubmit}
                >
                  ZAPISZ
                </StyledButton>
                <StyledButton
                  disabled={!isEditable || false}
                  onClick={() => history.push(`/programy`)}
                >
                  ANULUJ
                </StyledButton>
              </GuardedComponent>
            </Grid>
          </StyledGrid>
        </>
      )}
    </Formik>
  );
};

ProgramSettings.propTypes = {
  clearProgram: PropTypes.func,
  fetchProgram: PropTypes.func,
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    date_start: PropTypes.string,
    date_end: PropTypes.string,
    contract_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    is_pwd: PropTypes.bool,
    motivationalSystem: PropTypes.bool,
    type: PropTypes.number,
    color: PropTypes.string,
    organization: PropTypes.string,
    coordinator: PropTypes.number,
  }),
  clearOrganization: PropTypes.func,
  fetchOrganizationList: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
  clearProgramCoordinatorsList: PropTypes.func,
  fetchProgramCoordinatorsList: PropTypes.func,
  clearCoordinatorsList: PropTypes.func,
  fetchCoordinatorsList: PropTypes.func,
  coordinators: PropTypes.arrayOf(PropTypes.any),
  editProgram: PropTypes.func,
  isNew: PropTypes.bool,
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
};

ProgramSettings.defaultProps = {
  clearProgram: null,
  fetchProgram: null,
  details: {
    id: null,
    name: '',
    description: '',
    date_start: '',
    date_end: '',
    contract_number: null,
    is_pwd: false,
    motivationalSystem: false,
    type: 0,
    color: '',
    organization: '',
    coordinator: null,
  },
  clearOrganization: null,
  fetchOrganizationList: null,
  organizations: [],
  clearProgramCoordinatorsList: null,
  fetchProgramCoordinatorsList: null,
  clearCoordinatorsList: null,
  fetchCoordinatorsList: null,
  coordinators: [],
  editProgram: null,
  isNew: false,
  me: {
    user_role: [],
  },
};

const mapStateToProps = ({ programReducer, organizationReducer, userReducer }) => ({
  details: programReducer.program,
  organizations: organizationReducer.organizations,
  me: userReducer.me,
  coordinators:
    programReducer.program && programReducer.program.id
      ? programReducer.coordinators
      : organizationReducer.coordinators,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgram: () => dispatch(clearProgramAction()),
  fetchProgram: (id) => dispatch(fetchProgramService(id)),
  clearOrganization: () => dispatch(clearOrganizationAction()),
  fetchOrganizationList: () => dispatch(fetchOrganizationListService()),
  clearProgramCoordinatorsList: () => dispatch(clearProgramCoordinatorsListAction()),
  fetchProgramCoordinatorsList: (id) => dispatch(fetchProgramCoordinatorsListService(id)),
  clearCoordinatorsList: () => dispatch(clearCoordinatorsListAction()),
  fetchCoordinatorsList: (id) => dispatch(fetchCoordinatorsListService(id)),
  editProgram: (id, data) => dispatch(editProgramService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSettings);
