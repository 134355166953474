import {
  SHOW_PROGRAMS,
  CLEAR_PROGRAMS,
  FETCH_PROGRAM,
  FETCH_PROGRAM_CONSORTIUM,
  CLEAR_PROGRAM_CONSORTIUM,
  FETCH_PROGRAM_TASKS,
  CLEAR_PROGRAM_TASKS,
  FETCH_PROGRAM_IPP,
  CLEAR_PROGRAM_IPP,
  FETCH_PROGRAM_ACTIONS,
  CLEAR_PROGRAM_ACTIONS,
  FETCH_PROGRAM_USERS,
  CLEAR_PROGRAM_USERS,
  FETCH_PROGRAM_BENEFICIARES,
  CLEAR_PROGRAM_BENEFICIARES,
  FETCH_PROGRAM_INDICATORS,
  CLEAR_PROGRAM_INDICATORS,
  FETCH_PROGRAM_DIAGNOSIS,
  CLEAR_PROGRAM_DIAGNOSIS,
  FETCH_PROGRAM_COORDINATORS,
  CLEAR_PROGRAM_COORDINATORS,
  FETCH_PROGRAM_ORGANIZATIONS,
  FETCH_PROGRAM_TASK_COORDINATORS,
  CLEAR_PROGRAM,
  FETCH_PROGRAM_PWD_PRESENCE,
  CLEAR_PWD_PRESENCE,
  FETCH_PROGRAM_DATA_ADMINISTRATORS,
  FETCH_ACTION_GROUPS_SUCCESS,
  ADD_ACTION_GROUPS_SUCCESS,
} from 'constants/actionTypes';

export const initialState = {
  programs: [],
  program: {
    id: null,
    name: '',
    description: '',
    date_add: '',
    date_end: '',
    contract_number: null,
    is_pwd: false,
    type: null,
  },
  consortium: {
    id: null,
    leader_organization_id: null,
    name: '',
    onlyShow: false,
    type: null,
    organizations: [],
    invited_organizations: [],
    program_organizations: [],
    advanced_rodo: null,
  },
  tasks: [],
  ipp: {
    id: null,
    name: '',
    ipps: [],
  },
  actions: {
    program_id: null,
    program_name: '',
    program_actions: [],
  },
  users: [],
  program_organizations: [],
  data_administrators: [],
  beneficiaries: {
    id: null,
    program_name: '',
    program_beneficiaries: [],
  },
  indicators: {
    id: null,
    program_name: '',
    program_task: [],
  },
  diagnosis: {
    id: null,
    name: '',
    diagnosis: [],
  },
  coordinators: [],
  task_coordinators: [],
  presence: {
    active: [],
    beneficiaries: [],
    dates: [],
    motivationalSystem: null,
  },
  actionGroups: [],
};

const programReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_PROGRAMS:
      return { ...state, programs: payload.data };
    case CLEAR_PROGRAMS:
      return { ...state, programs: initialState.programs };
    case FETCH_PROGRAM:
      return { ...state, program: payload.data };
    case FETCH_PROGRAM_CONSORTIUM:
      return { ...state, consortium: payload.data };
    case CLEAR_PROGRAM_CONSORTIUM:
      return { ...state, consortium: initialState.consortium };
    case FETCH_PROGRAM_TASKS:
      return { ...state, tasks: payload.data };
    case CLEAR_PROGRAM_TASKS:
      return { ...state, tasks: initialState.tasks };
    case FETCH_PROGRAM_IPP:
      return { ...state, ipp: payload.data };
    case CLEAR_PROGRAM_IPP:
      return { ...state, ipp: initialState.ipp };
    case FETCH_PROGRAM_ACTIONS:
      return { ...state, actions: payload.data };
    case CLEAR_PROGRAM_ACTIONS:
      return { ...state, actions: initialState.actions };
    case FETCH_PROGRAM_USERS:
      return { ...state, users: payload.data };
    case CLEAR_PROGRAM_USERS:
      return { ...state, users: initialState.users };
    case FETCH_PROGRAM_BENEFICIARES:
      return { ...state, beneficiaries: payload.data };
    case CLEAR_PROGRAM_BENEFICIARES:
      return { ...state, beneficiaries: initialState.beneficiaries };
    case FETCH_PROGRAM_INDICATORS:
      return { ...state, indicators: payload.data };
    case CLEAR_PROGRAM_INDICATORS:
      return { ...state, indicators: initialState.indicators };
    case FETCH_PROGRAM_DIAGNOSIS:
      return { ...state, diagnosis: payload.data };
    case CLEAR_PROGRAM_DIAGNOSIS:
      return { ...state, diagnosis: initialState.diagnosis };
    case FETCH_PROGRAM_COORDINATORS:
      return { ...state, coordinators: payload.data };
    case CLEAR_PROGRAM_COORDINATORS:
      return { ...state, coordinators: initialState.coordinators };
    case FETCH_PROGRAM_ORGANIZATIONS:
      return { ...state, program_organizations: payload.data };
    case FETCH_PROGRAM_DATA_ADMINISTRATORS:
      return { ...state, data_administrators: payload.data };
    case FETCH_PROGRAM_TASK_COORDINATORS:
      return { ...state, task_coordinators: payload.data };
    case FETCH_PROGRAM_PWD_PRESENCE:
      return { ...state, presence: payload.data };
    case CLEAR_PWD_PRESENCE:
      return { ...state, presence: initialState.presence };
    case FETCH_ACTION_GROUPS_SUCCESS:
      return { ...state, actionGroups: payload.data };
    case ADD_ACTION_GROUPS_SUCCESS:
      return { ...state, actionGroups: payload.data };
    case CLEAR_PROGRAM:
      return { ...initialState };
    default:
      return state;
  }
};

export default programReducer;
